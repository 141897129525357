import React, { useContext, useRef } from 'react';
import { EditorContext } from '../EditorContext'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/styles/overlayscrollbars.css';
import Frame from './Frame';
import DnDContext from '../dragNdrop/DnDContext';
import dots from './helpers/dots.svg'
import { CursorContext } from '../CursorContext';
import { NavArrowRight } from 'iconoir-react';

export default function Viewport({ panState }) {
    const { 
        selector, 
        view,
        setSelector,
        ds,
        project,
        currentThemeId,
        streaming,
        setView,
        dndState,
        defaultIconSet,
        handleAction,
    } = useContext(EditorContext);

    const { hoveredObjId, handleMouseEnter, handleMouseLeave } = useContext(CursorContext);
    
    const {handleDragStart, handleDragOver, handleDragEnd, collectRefs} = useContext(DnDContext)

    const frame = selector?.frame;
    const fitCanvas = panState?.fitCanvas;

    const viewportWidth = 1440
    const viewportHeight = 900

    const canvasWidth = panState.canvasDimensions?.width
    const canvasHeight = panState.canvasDimensions?.height

    // Calculate scale factors
    const scaleByWidth = canvasWidth / viewportWidth
    const scaleByHeight = canvasHeight / viewportHeight;

    

    // Choose the smaller scale factor and ensure it does not exceed 1
    const scale = Math.min(scaleByHeight, scaleByWidth, 1);

    const viewportRef = useRef(null);

    const translateX = (canvasWidth - viewportWidth * scale) / 2
    const fitStyles = {
            width: viewportWidth,
            minWidth: viewportWidth,
            height: viewportHeight,
            minHeight: viewportHeight,
            transform: `translate(${translateX}px, 0px) scale(${scale})`,
            transformOrigin: 'top left',
            overflowY: 'scroll',
            overflowX: 'hidden',
    }
    const noFrame = !frame
    // backgroundImage: `url(${dots})`,
    return (
        <>
        {/*
        <div className='text-xs flex flex-row gap-0.5 items-center mb-2 font-medium'
        style={{transform: `translate(${translateX}px, 0px)`}}
        >
            <span>{selector.page?.name}</span>
            <NavArrowRight widht={16} height={16}/>
            {selector.frame?.name}
        </div>*/}
        <div 
        className={`flex flex-col items-start justify-stretch relative select-none 
        ${noFrame ? 'bg-transparent' : 'bg-base-0'} rounded-sm`}
        ref={viewportRef}
        onPointerDown={e=> e.stopPropagation()}
        style={{
            ...fitStyles,
            boxShadow: !noFrame && `0 0 0 ${Math.max(1/scale, 1)}px rgba(0,0,0,0.16)`,
            pointerEvents: streaming && 'none', 
        }}
        >  
        <OverlayScrollbarsComponent
            options={{ 
                className: "os-theme-dark",
                scrollbars: {
                    autoHide: 'scroll',
                    autoHideDelay: 800
                }
            }}
          style={{ width: '100%', height: '100%' }}
        >
        {/*
        {scale}<br/>
        canvas {canvasWidth} x {canvasHeight}<br/>
        viewport {viewportWidth} x {viewportHeight}<br/>
        translated {viewportWidth * scale} x {viewportHeight * scale}<br/> */}
        
        {/* ACTUAL FRAME */}
        {frame && 
        <Frame
            selector={selector}
            setSelector={setSelector}
            ds={ds}
            project={project}
            currentThemeId={currentThemeId}
            scale={scale}
            view={view} setView={setView}
            dndState={dndState}
            handleAction={handleAction}
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDragEnd={handleDragEnd}
            collectRefs={collectRefs}
            streaming={streaming}
            defaultIconSet={defaultIconSet}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            hoveredObjId={hoveredObjId}
            
        />}
        </OverlayScrollbarsComponent>
        </div>
        </>
        
    )
}

