import { useContext, useEffect, useState } from "react"
import { Button, MiniSnippet, TextArea } from "../../../../../ui-kit/exports/react"
import UserContext from "../../../../../UserContext"
import * as UIKit from '../../../../../ui-kit/local/index'
import { preparePrimitives } from "../../library/helpers"
import { listUniqueComponents } from "./helpers"


const systemPrompt = `
    You are a skilled React developer tasked with turning a design into a functional react component.
    You will receive JSX code that represents the design and a description of the component's behavior.
    Your task is to write the component code that matches the design and behavior description.
    You will only use the components from the JSX code and props provided.

    Some tips: 
    - suggest data objects that can be used to populate the component
    - suggest endpoints that can be called to fetch data
    - suggest state management strategies
  
    You will only reply with React snippet, not yapping.
    Don't wrap it in a code block.
  `

export default function Fiddle({ jsx, selector }) {

    const [loading, setLoading] = useState(false)
    const [ userDescription, setUserDescription ] = useState('')
    const primitives = preparePrimitives(UIKit)
    const uniqueComponents = listUniqueComponents(selector)
    const propTypes = primitives.filter(p => uniqueComponents.includes(p.apiName))
    const [response, setResponse] = useState('')

    const { user } = useContext(UserContext);
    const [ws, setWs] = useState(null);
    useEffect(() => {
        return () => {
          if (ws) {
            ws.close();
          }
        };
      }, [ws]);

      const handleWebSocketMessages = (event) => {
        const data = JSON.parse(event.data);
        
        const {action, payload} = data;

        // console.log('action', data)

        if (action === 'unlockCanvas') {
            setLoading(false) 
        }

        if (action === 'stream') {
            setResponse(payload.accumulatedResponse)
        }
        
      };

      const startWebSocketConnection = () => {
        let webSocketUrl;
        if (process.env.NODE_ENV === "development") {
          webSocketUrl = "ws://localhost:3300/ai/generate-fiddle";
        } else {
          const protocolPrefix = window.location.protocol === "https:" ? "wss://" : "ws://";
          webSocketUrl = `${protocolPrefix}${window.location.host}/ai/generate-fiddle`;
        }
    
        const webSocket = new WebSocket(webSocketUrl);
    
        // Assign WebSocket event handlers
        webSocket.onmessage = handleWebSocketMessages;
        webSocket.onerror = (error) => console.error("WebSocket Error:", error);
        webSocket.onclose = (event) => {
          console.log("WebSocket Disconnected", event.reason);
          setWs(null);
        };
    
        setWs(webSocket);
        return webSocket;
      };
    
      // Function to handle form submission
      const handleSubmit = () => {
        // Start WebSocket connection if it's not already established
        if (!ws || ws.readyState !== WebSocket.OPEN) {
          const webSocket = startWebSocketConnection();
    
          const userPrompt = `User Description: ${userDescription}
          
          Current JSX: ${jsx}

          Props: ${JSON.stringify(propTypes)}
          `;
          // Wait for the connection to open before sending the payload
          webSocket.onopen = () => {
            const payload = {
                jsx,
                systemPrompt,
                userPrompt
            };
            // console.log('payload', payload)
            setLoading(true)
            webSocket.send(JSON.stringify({type: 'message', payload}));
          };
        } else {
          // If WebSocket is already open, send the empty payload directly
          ws.send(JSON.stringify({}));
        }
      };
    
    
    return (
      <div className="flex flex-col gap-2">
      <TextArea
        value={userDescription}
        onChange={(e) => setUserDescription(e.target.value)}
        label={null}
        maxrows={16}
        defaultrows={8}
        placeholder="Describe what the page should do, possibly include data json, instructions on endpoint calls, etc."
      
      />
      <Button 
        size={'small'} 
        color={'base-700'} 
        state={loading ? 'loading' : 'default'}
        text={'Generate'} 
        style={'filled'}
        leftIcon={'flare'}
        onClick={handleSubmit}
        
        />
        {response && response.length > 0 &&
        <MiniSnippet
            whiteSpace={'pre'}
            hightlightSyntax={true}
            maxHeight={380}
            text={response}
            />}
      </div>
    )
  }



  