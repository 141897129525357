export const safelist = `safelist: [

/* add to safelist 

bg-base-0/75 bg-base-50/75 bg-base-100/75 bg-base-200/75 bg-base-300/75 bg-base-400/75 bg-base-500/75 bg-base-600/75 bg-base-700/75 bg-base-800/75 bg-base-900/75 bg-base-content/75
bg-primary/75 bg-primary-focus/75 bg-primary-content/75 
bg-accent/75 bg-accent-focus/75 bg-accent-content/75
bg-info/75 bg-info-focus/75 bg-info-content/75
bg-success/75 bg-success-focus/75 bg-success-content/75
bg-warning/75 bg-warning-focus/75 bg-warning-content/75
bg-error/75 bg-error-focus/75 bg-error-content/75

hover:bg-base-0/75 hover:bg-base-50/75 hover:bg-base-100/75 hover:bg-base-200/75 hover:bg-base-300/75 hover:bg-base-400/75 hover:bg-base-500/75 hover:bg-base-600/75 hover:bg-base-700/75 hover:bg-base-800/75 hover:bg-base-900/75 hover:bg-base-content/75
hover:bg-primary/75 hover:bg-primary-focus/75 hover:bg-primary-content/75
hover:bg-accent/75 hover:bg-accent-focus/75 hover:bg-accent-content/75
hover:bg-info/75 hover:bg-info-focus/75 hover:bg-info-content/75
hover:bg-success/75 hover:bg-success-focus/75 hover:bg-success-content/75
hover:bg-warning/75 hover:bg-warning-focus/75 hover:bg-warning-content/75
hover:bg-error/75 hover:bg-error-focus/75 hover:bg-error-content/75
-left-2 -left-4 -right-2 -right-4 -top-2 -top-4 -bottom-2 -bottom-4

// hover:ring-primary/50 hover:ring-accent/50 hover:ring-base-0/50 hover:ring-base-100/50 hover:ring-base-200/50 hover:ring-base-300/50 hover:ring-base-700/50 hover:ring-error/50 hover:ring-success/50 hover:ring-warning/50

// ring-info/50 ring-success/50 ring-base-200/50 ring-base-700/50 ring-warning/50 ring-error/50
    // bg-info/10 bg-success/10 bg-base-200/10 bg-base-700/10 bg-warning/10 bg-error/10
    // bg-info/20 bg-success/20 bg-base-200/20 bg-base-700/20 bg-warning/20 bg-error/20

// tailwindcss safelist
// gap-0.5 gap-1 gap-1.5 gap-2 gap-2.5 gap-3 gap-3.5 gap-4 gap-5 gap-6 gap-8 gap-10 gap-12 
// px-0.5 px-1 px-1.5 px-2 px-2.5 px-3 px-3.5 px-4 px-5 px-6 px-8 px-10 px-12
// py-0.5 py-1 py-1.5 py-2 py-2.5 py-3 py-3.5 py-4 py-5 py-6 py-8 py-10 py-12

// pr-0.5 pr-1 pr-1.5 pr-2 pr-2.5 pl-0.5 pl-1 pl-1.5 pl-2 pl-2.5
    // ml-0.5 ml-1 ml-1.5 ml-2 ml-2.5 mr-0.5 mr-1 mr-1.5 mr-2 mr-2.5

// 'mt-0.5', 'mt-1', 'mt-1.5', 'mt-2', 'mt-3', 'mt-4', 'mt-6', 'mt-8', 'mt-12', 'mt-16',
    // mt-[1px] mt-[2px] mt-[6px] mt-[8px] mt-[12px] mt-[16px] mt-[24px] mt-[32px] mt-[40px] mt-[48px] mt-[56px] mt-[64px]
    // bg-base-200/20 bg-base-700/20 bg-base-content/20 bg-current-20 bg-accent/20 bg-primary/20 bg-info/20 bg-error/20 bg-warning/20 bg-success/20
    // ring-current
*/
    // pr-0.5 pr-1 pr-1.5 pr-2 pr-2.5 pl-0.5 pl-1 pl-1.5 pl-2 pl-2.5
    // ml-0.5 ml-1 ml-1.5 ml-2 ml-2.5 mr-0.5 mr-1 mr-1.5 mr-2 mr-2.5

    
    // Colors
    'bg-primary', 'bg-primary-content', 'bg-primary-focus', 'bg-primary-surface',
    'bg-secondary', 'bg-secondary-content', 'bg-secondary-focus', 'bg-secondary-surface',
    'bg-accent', 'bg-accent-content', 'bg-accent-focus', 'bg-accent-surface',
    'bg-base-0', 'bg-base-100', 'bg-base-200', 'bg-base-300', 'bg-base-400', 'bg-base-500', 'bg-base-600', 'bg-base-700', 'bg-base-900', 'bg-base-content',
    'bg-error', 'bg-error-content', 'bg-error-focus', 'bg-error-surface',
    'bg-info', 'bg-info-content', 'bg-info-focus', 'bg-info-surface',
    'bg-success', 'bg-success-content', 'bg-success-focus', 'bg-success-surface',
    'bg-warning', 'bg-warning-content', 'bg-warning-focus', 'bg-warning-surface',

    'text-primary', 'text-primary-content', 'text-primary-focus',
    'text-secondary', 'text-secondary-content', 'text-secondary-focus',
    'text-accent', 'text-accent-content', 'text-accent-focus',
    'text-base-0', 'text-base-100', 'text-base-200', 'text-base-300', 'text-base-400', 'text-base-500', 'text-base-600', 'text-base-700', 'text-base-900',
    'text-error', 'text-error-content', 'text-error-focus',
    'text-info', 'text-info-content', 'text-info-focus',
    'text-success', 'text-success-content', 'text-success-focus',
    'text-warning', 'text-warning-content', 'text-warning-focus',

    'border-primary', 'border-primary-content', 'border-primary-focus',
    'border-secondary', 'border-secondary-content', 'border-secondary-focus',
    'border-accent', 'border-accent-content', 'border-accent-focus',
    'border-base-0', 'border-base-100', 'border-base-200', 'border-base-300', 'border-base-400', 'border-base-500', 'border-base-600', 'border-base-700', 'border-base-900',
    'border-error', 'border-error-content', 'border-error-focus',
    'border-info', 'border-info-content', 'border-info-focus',
    'border-success', 'border-success-content', 'border-success-focus',
    'border-warning', 'border-warning-content', 'border-warning-focus',
    
    'outline-primary', 'outline-primary-content', 'outline-primary-focus',
    'outline-secondary', 'outline-secondary-content', 'outline-secondary-focus',
    'outline-accent', 'outline-accent-content', 'outline-accent-focus',
    'outline-base-0', 'outline-base-100', 'outline-base-200', 'outline-base-300', 'outline-base-400', 'outline-base-500', 'outline-base-600', 'outline-base-700', 'outline-base-900',
    'outline-error', 'outline-error-content', 'outline-error-focus',
    'outline-info', 'outline-info-content', 'outline-info-focus',
    'outline-success', 'outline-success-content', 'outline-success-focus',
    'outline-warning', 'outline-warning-content', 'outline-warning-focus',

    'ring-primary', 'ring-primary-content', 'ring-primary-focus',
    'ring-secondary', 'ring-secondary-content', 'ring-secondary-focus',
    'ring-accent', 'ring-accent-content', 'ring-accent-focus',
    'ring-base-0', 'ring-base-100', 'ring-base-200', 'ring-base-300', 'ring-base-400', 'ring-base-500', 'ring-base-600', 'ring-base-700', 'ring-base-900',
    'ring-error', 'ring-error-content', 'ring-error-focus',
    'ring-info', 'ring-info-content', 'ring-info-focus',
    'ring-success', 'ring-success-content', 'ring-success-focus',
    'ring-warning', 'ring-warning-content', 'ring-warning-focus',
    
    // Gaps
    'gap-3xs', 'gap-2xs', 'gap-xs', 'gap-sm', 'gap-md', 'gap-lg', 'gap-xl', 'gap-2xl', 'gap-3xl', 'gap-base',

    // Padding
    'p-3xs', 'p-2xs', 'p-xs', 'p-sm', 'p-md', 'p-lg', 'p-xl', 'p-2xl', 'p-3xl', 'p-base', 
    'px-3xs', 'px-2xs', 'px-xs', 'px-sm', 'px-md', 'px-lg', 'px-xl', 'px-2xl', 'px-3xl', 'px-base',
    'py-3xs', 'py-2xs', 'py-xs', 'py-sm', 'py-md', 'py-lg', 'py-xl', 'py-2xl', 'py-3xl', 'py-base',

    // Margins
    'm-3xs', 'm-2xs', 'm-xs', 'm-sm', 'm-md', 'm-lg', 'm-xl', 'm-2xl', 'm-3xl', 'm-base',
    'mx-3xs', 'mx-2xs', 'mx-xs', 'mx-sm', 'mx-md', 'mx-lg', 'mx-xl', 'mx-2xl', 'mx-3xl', 'mx-base',
    'my-3xs', 'my-2xs', 'my-xs', 'my-sm', 'my-md', 'my-lg', 'my-xl', 'my-2xl', 'my-3xl', 'my-base',
    'mt-3xs', 'mt-2xs', 'mt-xs', 'mt-sm', 'mt-md', 'mt-lg', 'mt-xl', 'mt-2xl', 'mt-3xl', 'mt-base',
    'mb-3xs', 'mb-2xs', 'mb-xs', 'mb-sm', 'mb-md', 'mb-lg', 'mb-xl', 'mb-2xl', 'mb-3xl', 'mb-base',

    // Text
    'text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl',

    // Radius
    'rounded-2xs', 'rounded-xs', 'rounded-sm', 'rounded-md', 'rounded-lg', 'rounded-xl', 'rounded-2xl', 'rounded-3xl', 'rounded-base', 
]`.trim();