type LoaderProps = {
    size?: '12px' | '16px' | '20px' | '24px' | '28px',
    type?: 'spinner' | 'pulse',
    color?: 'base-0' | 'base-100' | 'base-content' | 'primary' | 'accent' | 'error' | 'warning' | 'success' | 'info' | string,
    opacity?: '100' | '70' | '50',
    __juno?: any
}

export default function Loader({
        size='16px', 
        type='spinner', 
        color,
        opacity='70', 
        __juno = {},
    }: LoaderProps) {
    
    const borderSizeMap = {
        '12px': 'border',
        '16px': 'border-2',
        '20px': 'border-[3px]',
        '24px': 'border-4',
        '28px': 'border-[5px]'
    };
    
    // border-current 
    // border-r-primary/20 border-t-primary/20 
    // border-r-accent/20 border-t-accent/20
    // border-r-error/20 border-t-error/20
    // border-r-warning/20 border-t-warning/20
    // border-r-success/20 border-t-success/20
    // border-r-info/20 border-t-info/20
    
    const useColor = color ? color : 'current'
    const typeClasses = type == 'spinner' ? `${borderSizeMap[size]} border-solid border-${useColor} border-r-${useColor}/20 border-t-${useColor}/20 ` :
    `bg-${useColor} `
    
    const animation = type == 'spinner' ? 'animate-spin' : 'animate-pulse' 
    const classes = `${typeClasses} rounded-full opacity-${opacity}
${animation}`

    return (
        <div className={__juno?.outlineStyle} {...__juno?.attributes}>
            <div 
                style={{width: size, height: size}}
                className={classes}/>
        </div> 
    );
}



