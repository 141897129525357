import { spacingMap } from '../helpers';

type GridProps = {
    paddingX?: '2px' | '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    paddingY?: '2px' | '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    gap?: '2px' | '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    background?: 'base-0' | 'base-50' | 'base-100' | 'base-200' | 'primary' | 'accent' | 'base-900',
    display?: boolean,
    columnCount?: number,
    alignItems?: 'start' | 'center' | 'end' | 'stretch',
    corners?: 'none' | 'sm' | 'md' | 'lg' | 'full',
    hasOutline?: boolean,
    width?: 'full' | 'auto' | '1/2' | '1/3' | '1/4' | '2/3' | '3/4',
    height?: 'full' | 'auto' | '1/2' | '1/3' | '1/4' | '2/3',
    fontColor?: 'base-0' | 'base-100' | 'base-200' | 'base-300' | 'base-500' | 'base-700' | 'primary' | 'accent' | 'base-900' | 'base-content' | 'auto' | 'success' | 'error' | 'warning' | 'info' | 'success-content' | 'error-content' | 'warning-content' | 'info-content',
    children?: any,
    
    __juno?: any
}

export default function Grid({
        paddingX,
        paddingY,
        gap,

        background,
        
        
        columnCount = 3,
        alignItems = 'start',
        corners = 'none',
        hasOutline = false,
        width = 'full',
        height,
        fontColor,
        display = true,
        children,
        __juno = {}
    }: GridProps) {

    const bgStyles = background ? `bg-${background}` : '';
    
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';
    const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;
    
    const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    const widthStyles = `w-${width}`;
    const heightStyles = height ? `h-${height}` : '';
    const inheritFontStyle = (!background) ? '' : (background?.startsWith('base') && background != 'base-900') ? `text-base-content` : `text-base-0`;
    const fontStyles = (fontColor == 'auto' || !fontColor) ? inheritFontStyle : `text-${fontColor}`
    
    let borderStyles = hasOutline ? 'border border-base-300' : '';
    const classes = `grid ${borderStyles} ${widthStyles} ${bgStyles} ${cornerStyles} ${gapStyles} ${paddingStyles} ${heightStyles} ${fontStyles}`;
    
    if (display) return (
        <div 
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        {...__juno?.attributes}
        style={{ alignItems: alignItems, gridTemplateColumns: columnCount ? `repeat(${columnCount}, minmax(0, 1fr))` : undefined }}
        >
        {children}
        </div>
    );
}


/* ignore rest */

Grid.definitions = {
    apiName: 'Grid',
    displayName: 'Grid',
    description: 'A grid layout component for organizing content in a structured format, with customizable properties like padding, background color, gap, column count, and alignment options. Supports dynamic and responsive layouts.',
    ai_instructions: 'grid component. Ideal for creating structured, dynamic, and responsive layouts.',
    type: 'container',
    relativeSize: 'medium to large',
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    status: 'stable',
    package: null,
    propDefinitions: {
        width: {
            type: "oneOf",
            options: ["full", "auto", "1/2", "1/3", "1/4", "2/3", '3/4'],
            displayName: "Width",
            default: "full", 
            tile: '1/2'
        },
        height: {
            type: "oneOf",
            options: ["full", "auto", "1/2", "1/3", "1/4", "2/3"],
            displayName: "Height",
            default: "auto", 
            tile: '1/2'
        },
        columnCount: {
            type: 'number',
            displayName: 'Columns',
            default: 3, 
            defaultOnMobile: 1,
            tile: '1/2'
        },
        alignItems: {
            type: "alignItems",
            options: ["start", "center", "end", "stretch"],
            displayName: "Align Items",
            default: "start", 
            tile: '1/2'
        },
        background: {
            type: "colors",
            options: ["base-0", 'base-50', "base-100", "base-200", "primary", "accent", "base-900", "none"],
            displayName: "Background",
            default: "none", 
            tile: '1/2',
        },

        fontColor: {
            type: 'colors',
            options: ['base-0', 'base-100', 'base-200', 'base-300', 'base-500', 'base-700', 'primary', 'accent', 'base-900', 'base-content', 'success', 'error', 'warning', 'info', 'success-content', 'error-content', 'warning-content', 'info-content'],
            displayName: 'Font Color',
            default: null,
            tile: '1/2',
        },
        paddingX: {
            type: "spacing",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right. in addition to overall padding property',
            default: 'base', 
            tile: '1/2',
        },
        paddingY: {
            type: "spacing",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom. in addition to overall padding property',
            default: null,
            tile: '1/2',
        },
        corners: {
            type: "corners",
            options: ["none", "sm", "md", "lg", 'xl', '2xl', "full"],
            displayName: "Corners",
            default: null, 
            tile: '1/2',
        },
        gap: {
            type: "gap",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Gap",
            default: null,
            tile: '1/2',
        },
        hasOutline: {
            type: "bool",
            displayName: "Outline",
            default: false, 
            ai_instructions: 'adds base-300 1px outline',
            tile: '1/2'
        },display: {
            type: 'bool',
            displayName: 'Show',
            default: true, 
            tile: '1/2'
        },

        
        
        
    }
};

/*
Grid.propTypes = {
    columnCount: PropTypes.number,
    background: PropTypes.oneOf(['base-0', 'base-50', 'base-100', 'base-200', 'primary', 'accent', 'base-900']),
    fontColor: PropTypes.oneOf(['base-0', 'base-100', 'base-200', 'base-300', 'base-500', 'base-700', 'primary', 'accent', 'base-900', 'base-content', 'auto', 
        'success', 'error', 'warning', 'info', 'success-content', 'error-content', 'warning-content', 'info-content']),
    
    paddingX: PropTypes.oneOf(["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    paddingY: PropTypes.oneOf(["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    gap: PropTypes.oneOf(["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    
    display: PropTypes.bool,
    corners: PropTypes.oneOf(["none", "sm", "md", "lg", "full"]),
    hasOutline: PropTypes.bool,
    width: PropTypes.oneOf(["full", "auto", "1/2", "1/3", "1/4", "2/3", '3/4']),
    height: PropTypes.oneOf(["full", "auto", "1/2", "1/3", "1/4", "2/3"]),
    alignItems: PropTypes.oneOf(["start", "center", "end", "stretch"]),
    children: PropTypes.node
};  */ 