import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { Icon } from '../';

const dummyData = {
    keys: ['name', 'temperature', 'humidity', 'pressure'],
    colors: ["primary", "accent", "base-500"],
    values: [
        ["Aug 12", 45, 30, 52],
        ["Aug 19", 34, 40, 48],
        ["Aug 26", 52, 35, 15],
        ["Sep 2", 51, 50, 80],
        ["Sep 9", 52, 45, 73],
        ["Sep 16", 60, 55, 75],
        ["Sep 23", 80, 60, 80],
    ]
}

type DataPoint = {
    [key: string]: string | number;
}

function transformData(keys: string[], values: (string | number)[][]): DataPoint[] {
    return values.map((values) =>
      values.reduce<DataPoint>((obj, val, index) => {      
        obj[keys[index]] = val;
        return obj;
      }, {}),
    );
  }


type LineChartProps = {
    width?: 'auto' | 'full' | '1/2',
    height?: '92px' | '120px' | '160px' | '200px' | '240px' | '360px' | string,
    lineColor?: 'primary' | 'accent' | 'base-content' | 'base-0' | 'success' | 'warning' | 'error' | 'info',   
    lineWidth?: '1' | '2' | '3' | '4',
    lineType?: 'wavy' | 'linear',
    showGrid?: boolean,
    showDots?: boolean,
    showLabels?: boolean,
    showYAxis?: boolean,
    showXAxis?: boolean,
    bottomDomain?: number | 'auto',
    topDomain?: number | 'auto',
    backgroundColor?: 'base-0' | 'base-100' | 'base-200' | string, 
    emptyState?: boolean,
    emptyMessage?: string,
    data?: {
        keys: string[],
        values: any[][]
        colors?: string[],
    },
    __juno?: any
}

export default function LineChartComponent({
    backgroundColor,
    width = 'full',
    height = '120px',
    lineColor = 'primary',
    lineWidth = '2',
    lineType = 'wavy',
    showDots = true,
    showLabels,
    showYAxis,
    showXAxis = true,
    showGrid,
    data = dummyData,  
    emptyState,
    bottomDomain = 'auto',
    topDomain = 'auto',
    emptyMessage = 'empty message',
    __juno = {}
  }: LineChartProps) {

    const widthStyles = `w-${width}`;
    // const paddingStyles = padding === 'none' ? 'p-0' : `p-${padding}`;
    // const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    const bgStyles = backgroundColor && backgroundColor != 'none' ? `bg-${backgroundColor}` : ''
    const fontColorStyles = backgroundColor && backgroundColor != 'none' ? `text-base-content` : 'text-inherit'

    const classes = `flex flex-col items-stretch relative ${widthStyles} ${bgStyles} ${fontColorStyles}`
    
    const emptyStyles = `flex flex-col justify-center items-center px-sm text-sm font-medium gap-2 rounded-md`

    const sampleData = data ? transformData(data?.keys, data?.values) : []

    
    const sideMargins = (!showYAxis && !showLabels && !showDots && !showXAxis) ? 0 : 20

    return (
        <div
            className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            {...__juno?.attributes}
        >
            {/* CHART */}
            {(!emptyState && data?.keys?.length && data?.values?.length) ?
            <div className='flex flex-row' style={{ 
                    width: '100%', 
                    height: height,  }}>
                <ResponsiveContainer width={'100%'} height="100%" >
                <LineChart data={sampleData} margin={{ top: 20, right: sideMargins, bottom: 0, left: sideMargins }}>
                    {showGrid && <CartesianGrid strokeDasharray="1 3" />}
                    <XAxis dataKey={data?.keys[0]} tick={{ fontSize: '12px'}} hide={!showXAxis} />
                    <YAxis width={20} tick={{ fontSize: '12px'}} domain={[bottomDomain, topDomain]} hide={!showYAxis} />
                    <Tooltip />
                    {data.keys.slice(1).map((key, index) => 
                    <Line 
                        key={key}
                        type={lineType === 'wavy' ? 'monotone' : 'linear'} 
                        dataKey={key}
                        stroke={data.colors?.[index] ? `var(--${data.colors[index]}` : `var(--${lineColor})`}
                        strokeWidth={lineWidth} // Configurable line width
                        dot={showDots} // Configurable dots visibility
                        activeDot={{ r: 4 }}
                    > 
                    {showLabels && <LabelList dataKey={data?.keys[1]} position="top" style={{ fill: `var(--${lineColor})`, fontSize: 10 }} />}
                    </Line>)}
                </LineChart>
            </ResponsiveContainer>
            </div>
            
            
        :
        <>
        <div className={emptyStyles} style={{ height: height, backgroundColor: `color-mix(in srgb, currentColor 8%, transparent)`}}>
            <Icon icon='chart-up' />
            <span className='font-normal text-sm'>
                {emptyMessage}
            </span>
        </div>
        </>
        }
        </div>
    );
}




