import { Sortable, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import FramePreview from "./FramePreview";

export default function MiniFrame({frame, isSelected, selectFrame,isDragPreview, draggingItem, selector, assets, variables}) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
      } = useSortable({ id: frame.id });

    const isDragged = frame?.id == draggingItem?.id

    const tileWidth = 88 // 
    const tileHeight = 50 //
    //hover:-translate-y-2
    const frameStyles = `rounded-md bg-base-0 flex flex-col flex-shrink-0 flex-grow-0  border border-base-300 relative overflow-hidden
    ${!isDragging && 'transition-all duration-75  hover:scale-105'}
    ${isDragged && 'opacity-0'}
    ` 
    const selectedStyles = `${!isDragging ? '!scale-[1.02]' : ''}`
    
  
      const style = {
        transform: CSS.Transform.toString(transform),
        transition,
      };

      const scaleFactor = Math.round(tileWidth / 1440 * 100) / 100
      const noObjectSelected = !selector?.object

    return (
      <div className={`w-auto flex-shrink-0 flex flex-col h-full text-xs text-base-500 relative ${isDragged && 'opacity-0'}`}
      style={{width: tileWidth, height: tileHeight, maxHeight: tileHeight, overflow: 'visible'}}
      >   
      {/* wrapper is necessary to prevent nav list container from growing in height during dnd */}
        <div    
            className={`${frameStyles} ${isSelected && noObjectSelected && !draggingItem && selectedStyles}`}
            onClick={()=>selectFrame(frame)}
            
            ref={setNodeRef} id={frame.id} 
            style={{...style, 
                width: tileWidth, 
                height: tileHeight,
                maxHeight: tileHeight,
                boxShadow: draggingItem ? '' : isSelected && `0 0 0 1px var(--base-0), 0 0 0 ${noObjectSelected ? '3px var(--primary)' : '2px var(--base-500)'}`,
                boxSizing: 'border-box'
              }}
            
            {...attributes} {...listeners}
            >
              
                {<div 
            style={{width: 1440, height: '100%', minHeight: 820, transform: `scale(${scaleFactor})`, transformOrigin: 'top left'}}
            className="flex items-start justify-start flex-grow"
            >
          {<FramePreview
          frame={frame}
          assets={assets}
          effectiveVariables={variables} 
          scaleFactor={scaleFactor}
        />}
          </div>}
        </div>
    </div>)
  }



