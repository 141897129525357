import React, { useEffect, useState } from 'react';
import { Tooltip, Icon } from '..';
import { IconType, allIconNames } from '../iconMap';

type SidebarLinkProps = {
    text?: string,
    fontWeight?: 'auto' | 'light' | 'normal' | 'medium' | 'semibold',
    leftIcon?: IconType,
    size?: 'small' | 'medium' | 'large',
    width?: 'auto' | 'full',
    displayChildren?: boolean,
    indentLevel?: '0' | '1' | '2',
    color?: 'base-0' | 'base-50' | 'base-100' | 'base-200' | 'primary' | 'accent' | 'none',
    isCollapsed?: boolean,
    isActive?: boolean,
    usePadding?: boolean,
    alwaysOpen?: boolean,
    innerTree?: boolean,
    hoverEffect?: boolean,
    onClick?: () => void,
    children?: React.ReactNode,
    __juno?: any
}

export default function SidebarLink({
        text = 'Item',
        fontWeight = 'auto',
        leftIcon,
        size = 'medium',
        displayChildren = true,
        width = 'full',
        indentLevel = '0',
        color = 'none',
        isCollapsed = false,
        isActive = false,
        usePadding = true,
        hoverEffect = false,
        alwaysOpen = false,
        innerTree = false,
        onClick = () => {},
        children,
        __juno = {}
      }: SidebarLinkProps) {

    const fontWeightStyles = fontWeight == 'auto' ? 'font-normal' : `font-${fontWeight}`
    
    const sizeStylesMap = {
        small: usePadding ? `px-2 py-1 text-xs` : `py-1 px-0 text-xs`,
        medium: usePadding ? `px-3 py-1.5 text-sm` : `py-1.5 px-0 text-sm`,
        large: usePadding ? `px-4 py-2.5 text-base` : `py-2.5 px-0 text-base`
    }

    const gapStyles = isCollapsed ? 'gap-0' : size == 'small' ? 'gap-2' : size == 'large' ? 'gap-3' : 'gap-2.5' // was 3 for medium
    
    const sizeStyles = sizeStylesMap[size] || sizeStylesMap['medium']

    const widthStyle = (width == 'auto' || isCollapsed) ? `w-auto` : `w-${width} self-stretch`
    
    const cornerStyles = size == "small" ? "rounded" : size == "large" ? "rounded-lg" : "rounded-md"
    const borderStyles = `border border-transparent`
    
    const innerGap = size == 'small' ? 'gap-0.5' : size == 'large' ? 'gap-1.5' : 'gap-1'
    const classes = `transition-all duration-75 relative group flex flex-col duration-75 ${widthStyle} ${fontWeightStyles} ${innerGap}`

    const hoverStyles = 
    hoverEffect ? isActive ? 'bg-current-5' : 'md:hover:bg-current-5'
    : isActive ? 'opacity-100' : 'opacity-80' 
    

    let innerClasses = `transition-all relative flex flex-row items-center justify-between cursor-default duration-75 select-none
        ${hoverStyles} w-full ${sizeStyles} ${cornerStyles} ${borderStyles} ${gapStyles}` 

    const iconWidth = size == 'small' ? 'w-4 h-4' : size == 'large' ? 'w-6 h-6' : 'w-5 h-5'
    const IndentElement = <><div className={`flex-shrink-0 ${iconWidth}`}/></>
    const indentValue = parseInt(indentLevel) || 0

    const LeftIconComponent = leftIcon ? <Icon icon={leftIcon} className={`flex-shrink-0  ${iconWidth}`} /> : null;
    
    const [isOpen, setIsOpen] = useState(alwaysOpen ? true : displayChildren)
    useEffect(() => {
        setIsOpen(displayChildren)
    }, [displayChildren, alwaysOpen])
    
    
    return (
        <div 
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`} 
            {...__juno?.attributes}
        >
        <div
            className={innerClasses}
            /* replace to '            onClick={onClick}' */
            style={{
                color: color != 'none' && `var(--${color})`,
                }}>
            {!isCollapsed && Array(indentValue)?.fill(null)?.map((_, index) => (
            <React.Fragment key={index}>{IndentElement}</React.Fragment>
            ))}
            {LeftIconComponent}

            
            
            <div className={`flex flex-row flex-grow justify-between ${gapStyles} transition-all duration-0 ${isCollapsed ? 'w-0 h-0 pointer-events-none opacity-0' : 'w-auto'}`}>
                {text}
                {children && !alwaysOpen ?
                        <Icon icon={'chevron-down'} 
                        onClick={()=>setIsOpen(!isOpen)}
                        className={`flex-shrink-0 text-xs ${iconWidth} scale-90 my-auto transition-all ${isCollapsed ? 'opacity-0' : 'opacity-0 group-hover:opacity-60'} transition-all ${isOpen ? 'rotate-180' : 'rotate-0'}`} 
                        />
                : null
                }
            </div>
            {isCollapsed && text != '' &&
                <Tooltip 
                    direction='right' // up, down, left, right // need to add a prop
                    size={size == 'small' ? 'small' : 'medium'} 
                    bgColor='base-content' 
                    text={text} 
                    // __junoProps={{isSelected}} /* remove line */
                    />
            }
        </div>
        {isOpen && !isCollapsed && children &&
        <div className={`w-full flex flex-row ${gapStyles}`}>
        {innerTree && 
        <div className={`flex-shrink-0 ${iconWidth} !h-full relative`}>
            <div className={`w-px bg-current-20 absolute top-1 bottom-1 -right-px`}/>
        </div>}
        <div className={`${isOpen ? 'h-auto' : 'h-0'} w-full flex flex-col ${innerGap} transition-all`}>
        {children}
        </div>
        </div>
        }
        </div>
    );
}



/* ignore rest */

SidebarLink.stories = [
    {
        name: 'Default', 
        wrapperClasses: 'flex flex-col w-[220px] h-auto p-4 bg-white !bg-base-0',
        props: {
            text: 'Sidebar Link',
        }
    }
]

SidebarLink.definitions = {
    apiName: 'SidebarLink',
    displayName: 'Sidebar Link',
    description: 'Special navigation link that goes into sidebar',
    ai_instructions: 'navigation link that goes into sidebar. generally the higher the indent the deeper the navigation level. at indent level higher than 1 the use of icons is not recommended.',
    type: 'nav_links',
    relativeSize: 'small',
    package: 'Starter',
    acceptedChildren: 'none',
    propDefinitions: {
        text: {
            type: 'string',
            default: 'Item',
            editable: true
        },

        color: {
            type: 'colors',
            options: ['base-0', 'base-50', 'base-100', 'base-200', 'primary', 'accent', 'base-900', 'none'],
            displayName: 'Color',
            default: 'base-0', 
        },
        size: {
            type: 'size',
            options: ['small', 'medium', 'large'],
            displayName: 'Size',
            default: 'medium' , 
            tile: '1/2'
        },

        width: {
            type: "width",
            options: ["auto", "full"],
            displayName: "Width",
            default: "full", 
            tile: '1/2'
        },
        
        

        fontWeight: {
            type: 'oneOf',
            options: ['auto', 'light', 'normal', 'medium', 'semibold'],
            displayName: 'Font Weight',
            default: 'auto', 
            tile: '1/2'
        },
        indentLevel: {
            type: 'oneOf',
            displayName: 'Indent Level',
            options: ['0', '1', '2'],
            default: '0', 
            tile: '1/2'
        }, 
        leftIcon: {
            type: 'icon',
            options: ['none', ...allIconNames],
            displayName: 'Icon',
            default: 'Home' 
        },

        isCollapsed: {
            type: 'bool',
            displayName: 'Collapsed',
            default: false, 
            tile: '1/2'
        },
        isActive: {
            type: 'bool',
            displayName: 'Active',
            default: false, 
            tile: '1/2'
        },
        displayChildren: {
            type: 'bool',
            displayName: 'Show Children',
            default: true, 
            tile: '1/2'
        },

        usePadding: {
            type: 'bool',
            displayName: 'X Padding',
            default: true, 
            tile: '1/2'
        },
        hoverEffect: {
            type: 'bool',
            displayName: 'Hover Effect',
            default: false, 
            tile: '1/2'
        },
    }
};


/* const sizeStylesMap = {
        small: usePadding ? `p-1 text-xs` : `py-1 px-0 text-xs`,
        medium: usePadding ? `p-1.5 text-sm` : `py-1.5 px-0 text-sm`,
        large: usePadding ? `p-2.5 text-base` : `py-3 px-0 text-base`
    }
        
    SidebarLink.propTypes = {
    color: PropTypes.oneOfType([
        PropTypes.oneOf(["base-0", 'base-50', "base-100", "base-200", "primary", "accent", "none"]),
        PropTypes.string]),
    text: PropTypes.string,
    fontWeight: PropTypes.oneOf(['auto', 'light', 'normal', 'medium', 'semibold']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    leftIcon: PropTypes.oneOf(['none', ...allIconNames]),
    width: PropTypes.oneOf(['auto', 'full']),
    indentLevel: PropTypes.oneOf(['0', '1', '2']),
    isActive: PropTypes.bool,
    isCollapsed: PropTypes.bool,
    displayChildren: PropTypes.bool,
    children: PropTypes.node,
    usePadding: PropTypes.bool,
    hoverEffect: PropTypes.bool,
    onClick: PropTypes.func
};
    */
