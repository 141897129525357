import { FontQuestion, TextMagnifyingGlass } from "iconoir-react";
import { useEffect, useRef, useState } from "react";

export default function FontPicker({ theme, setTheme, fontPresets }) {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [savedFont, setSavedFont] = useState(null);
    const elementRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (elementRef.current && !elementRef.current.contains(event.target)) {
                setDropdownOpen(false);
                if (savedFont) { // Apply the saved font when dropdown closes
                    setTheme({...theme, font: savedFont});
                    setSavedFont(null); // Clear the saved font after applying
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [savedFont, theme, setTheme]); // Dependencies are added to ensure closure has the latest state

    const handleDropdownToggle = () => {
        setDropdownOpen(!isDropdownOpen);
        if (!isDropdownOpen) { // If we are about to open the dropdown
            setSavedFont(theme.font); // Save the current font when dropdown opens
        }
    };

    const optionStyle = `w-full px-3 py-1 rounded-md`;

    return (
        <div className='flex flex-col gap-1 items-start relative' ref={elementRef}>
            {/*<label className='text-xs'>Font</label>*/}
            <div 
                className={`w-full px-3 pl-2 py-1 rounded-md border flex flex-row justify-start items-center gap-2  transition-all cursor-pointer
                ${isDropdownOpen ? 'border-slate-300 !border-base-300' : 'border-transparent'} hover:border-slate-300 hover:!border-base-300
                bg-slate-100 !bg-base-100
                `}
                style={{fontFamily: theme.font}}
                onClick={handleDropdownToggle}
            >
                <TextMagnifyingGlass />
                {theme.font}
                
            </div>
            {isDropdownOpen && (
                <div className={`absolute top-full left-0 mt-2 w-full rounded-lg shadow-lg z-10 p-1 flex flex-col gap-1
                bg-white !bg-base-0
                border border-slate-200 !border-base-200
                `}>
                    {fontPresets.map(font => (
                        <div 
                            key={font} 
                            style={{fontFamily: font}}
                            className={`${optionStyle} ${theme.font === font ? 'bg-slate-100 !bg-base-100' : 'hover:bg-slate-100 hover:!bg-base-100'}`}
                            onMouseOver={() => setTheme({...theme, font: font})}
                            onMouseLeave={() => setTheme({...theme, font: savedFont || theme.font})}
                            onClick={() => {
                                setSavedFont(font);
                                setDropdownOpen(false);
                                setTheme({...theme, font: font}); // Apply immediately on click
                            }}
                        >
                            {font}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}