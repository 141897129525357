import { Calendar,  } from "iconoir-react";

export default function Hero({mainWidth}) {
    

    //
    return (
          <div className="flex flex-col h-full flex-shrink-0 gap-4 items-center justify-center md:justify-end mt-8 text-center py-4 w-full"
          style={{maxWidth: mainWidth}}
          >
              <h1 className="font-medium leading-tight text-2xl md:text-6xl brightness-110 text-primary
              bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary-focus"
                  style={{ 
                    fontFamily: 'Unbounded',

                   }}>
                  we make web apps
              </h1>
              <h1 className="text-xl md:text-2xl w-full font-normal text-gray-500">
                  while you raise, cust dev, or scale
              </h1>
              
              <HeroButton />
          </div>      
    )
  }



  function HeroButton() {

    const handleScrollToForm = () => {
      const element = document.getElementById('contact-form');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };
  
  
    return (
      <div 
        style={{zIndex: 5}}
        className={`py-2 px-3 whitespace-nowrap font-medium brightness-110 text-lg transition-all cursor-pointer
        bg-primary duration-150 hover:scale-105 relative group rounded-xl text-white
        mt-4 hover:shadow-lg
        
        flex flex-row items-center gap-2`}
        onClick={handleScrollToForm}

        >
           let's talk
           {<div className={` 
           
           transition-all duration-75 overflow-hidden
           `}
           
           >
            <Calendar className='w-5 h-5 stroke-[2px]'/>
          </div>}
          </div>
    )
  }
  