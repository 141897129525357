import React, { useState, useRef, useEffect, useCallback } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { InputText, ButtonIcon } from 'ui-kit/exports/react';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const defaultCenter = {
  lat: 35.221, // Coordinates around Amarillo, TX 79104
  lng: -101.8313,
};

// Example truck stop locations with fuel prices
const pinLocations = [
  { lat: 35.206, lng: -101.732, fuelPrice: '3.45', name: `Love's Travel Stop`, discount: '15¢' },
  { lat: 35.194, lng: -101.816, fuelPrice: '3.55', name: `Pilot Travel Center`, discount: '10¢' },
  { lat: 35.190, lng: -101.770, fuelPrice: '3.60', name: `Flying J Travel Center`, discount: '5¢' },
  { lat: 35.190, lng: -101.770, fuelPrice: '3.50', name: `T/A Travel Center`, discount: '10¢' },
];

// Define libraries outside the component
const libraries = ['places', 'marker'];

export default function GoogleMaps({ apiKey }) {
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [address, setAddress] = useState('');
  const [selectedPin, setSelectedPin] = useState(null); // Track selected marker
  const [mapLoaded, setMapLoaded] = useState(false);
  const [librariesLoaded, setLibrariesLoaded] = useState(false);
  const mapRef = useRef(null);
  const geocoder = useRef(null);
  const markersRef = useRef([]);

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleGeocode = () => {
    if (geocoder.current) {
      geocoder.current.geocode({ address: address }, (results, status) => {
        if (status === 'OK') {
          const location = results[0].geometry.location;
          setMapCenter({
            lat: location.lat(),
            lng: location.lng(),
          });
        } else {
          alert('Geocode was not successful for the following reason: ' + status);
        }
      });
    }
  };

  const mapOptions = {
    mapId: '157810715e2d2ecb', // Your Map ID
    disableDefaultUI: true,
    gestureHandling: 'greedy', // Allows basic gestures but disables zooming via scroll
    draggable: true, // Allows map to be draggable
    scrollwheel: true, // Enables zooming via scroll wheel
    disableDoubleClickZoom: true, // Disables zooming via double-click
  };

  const handleMapLoad = useCallback((map) => {
    mapRef.current = map;
    setMapLoaded(true);
  }, []);

  // Using useEffect to manually render custom markers
  useEffect(() => {
    if (
      mapLoaded &&
      librariesLoaded &&
      window.google?.maps?.marker &&
      mapRef.current
    ) {
      const { AdvancedMarkerElement } = window.google.maps.marker;

      // Clear any previous markers
      markersRef.current.forEach((marker) => marker.setMap(null));
      markersRef.current = [];

      // Create new markers
      pinLocations.forEach((location, index) => {
        const markerDiv = document.createElement('div');
        const preview = `<div class="relative flex flex-col items-center justify-center font-semibold rounded text-center transition duration-150
                bg-base-0 text-sm text-base-content drop-shadow px-1.5 py-0.5">
                $${location.fuelPrice}
            </div>
        `
         const selected  = `
            <div class="relative flex flex-row gap-2  items-center justify-center font-semibold rounded text-center transition duration-150
                ring-base-0 ring-1 bg-primary text-sm text-base-0  px-1.5 py-0.5">
                $${location.fuelPrice}
                <div class="text-xs flex flex-row gap-1 items-start gap-0.5 text-base-content font-medium px-2 py-1 absolute whitespace-nowrap top-1/2 right-3 pl-5 -translate-y-1/2 translate-x-full 
                bg-base-0 rounded-md ring-[0.5px] ring-current-20 -z-10">
                 
                ${location.name}
                <span class="text-xs font-medium text-primary">–${location.discount}</span>
                    
                    
                </div>
            </div>
            `;

        markerDiv.innerHTML = selectedPin === index ? selected : preview;

        const marker = new AdvancedMarkerElement({
          position: { lat: location.lat, lng: location.lng },
          map: mapRef.current,
          content: markerDiv,
        });

        // Add click listener for selection
        marker.addListener('click', () => setSelectedPin(index));
        markersRef.current.push(marker);
      });
    }
  }, [selectedPin, mapCenter, librariesLoaded, mapLoaded]);

  return (
    <div className="flex flex-col p-10">
      <div className="flex flex-col relative ring-[0.5px] ring-current-20 rounded-lg overflow-hidden">
        <AddressChange
          address={address}
          handleAddressChange={handleAddressChange}
          handleGeocode={handleGeocode}
        />
        {/* Google Map */}
        <LoadScript
          googleMapsApiKey={apiKey}
          libraries={libraries} // Use the constant libraries array
          onLoad={() => {
            geocoder.current = new window.google.maps.Geocoder();
            setLibrariesLoaded(true);
          }}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={12}
            options={mapOptions}
            onLoad={handleMapLoad}
          >
            {/* Markers are manually rendered via useEffect */}
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
}


function AddressChange({ address, handleAddressChange, handleGeocode }) {
  return (
    <div className='flex flex-row gap-1
    bg-base-0 w-auto h-auto absolute z-10 left-4 top-4 p-1 ring-[0.5px] ring-current-20
    rounded-lg'>
      <InputText
        value={address}
        onChange={handleAddressChange}
        placeholder='Address'
        size='small'
      />
      <ButtonIcon
        icon='search'
        size='small'
        style='filled'
        onClick={handleGeocode}
      />
    </div>
  );
}