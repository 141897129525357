import devLogos from "./dev_logos_2.svg";
import { useEffect, useRef, useState } from "react";
import { Button, Icon } from "../../ui-kit/exports/react";
import { Figma } from "iconoir-react";

export default function Services() {
    
    const themes = [
        {
            name: 'juno', 
            hFontFamily: 'Lato', 
            hFontColor: 'text-gray-800  font-semibold',
            fontFamily: 'Inter', 
            sectionBgClasses: 'bg-stone-100',
            textColor: 'text-gray-700', 
            boxRadius: 'rounded-xl', 
            buttonStyles: 'px-3 py-2 bg-primary text-white hover:bg-primary-focus rounded-xl', 
            isDark: false, 
            iconSet: 'iconoir', 
            
            
        }
    ]

    const [currentIndex, setCurrentIndex] = useState(0);
    const selectedTheme = themes[currentIndex];
    const [pingVisible, setPingVisible] = useState(false);

    
    
    const iconAttributes = {
        library: selectedTheme.iconSet,
        className: `scale-90 ${selectedTheme.iconClasses}`
    }



    const handleScrollToForm = () => {
        const element = document.getElementById('contact-form');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
        
        <div className={`flex flex-col w-full relative items-center h-auto  px-5 max-w-[1120px] mb-8
        
        `}>
            <div className="flex flex-col w-full flex-grow-0 flex-shrink-0 items-center px-5 py-20 md:pt-12 justify-center "
        style={{ fontFamily: 'Unbounded, Inter, system-ui, -apple-system, BlinkMacSystemFont, Roboto, sans-serif'}}

        >
            <h2 className="w-full mx-auto leading-relaxed text-base md:text-2xl text-center font-normal flex flex-col items-center justify-center">
            founders partner with us to
            <span className="text-primary text-xl md:text-4xl mt-3">plan, design & code apps</span>
            </h2>
            </div>

        <div className="flex flex-col md:flex-row w-full gap-8 items-stretch h-full  flex-grow justify-center  text-md" 
        style={{fontFamily: 'ui-monospace'}}
        >
            <Design 
                
                selectedTheme={selectedTheme}
                pingVisible={pingVisible}
                iconAttributes={iconAttributes}
                handleScrollToForm={handleScrollToForm}
            />
            
            <Development 
                iconAttributes={iconAttributes}
                selectedTheme={selectedTheme}
                handleScrollToForm={handleScrollToForm}
            />

            <ProductStrategy 
                iconAttributes={iconAttributes}
                selectedTheme={selectedTheme}
                handleScrollToForm={handleScrollToForm}
                
            /> 

            
            
            

            
                
            </div>
            
        
        </div>
        
    );
}

function Box({children, selectedTheme,}) {
    
    //hover:-translate-y-1
    return (
        <div className={`w-full md:w-1/3 h-auto md:h-full flex flex-col flex-grow gap-4  items-start justify-start relative group select-none
            transition-all  min-h-[400px] md:min-h-[480px]  ${selectedTheme.textColor} 
            px-6 pt-6 pb-3 bg-gradient-to-tl from-base-50 to-base-0 hover:-translate-y-2 hover:bg-base-0 ring-[0.5px] ring-slate-200 hover:ring-slate-300 !duration-150 hover:shadow-lg hover:shadow-base-100
            
            ${selectedTheme.boxRadius} font-normal overflow-hidden`}
            style={{fontFamily: `${selectedTheme.fontFamily}`}}
            >
            {children}
            
        </div>
    )
}


function Design({
    handleScrollToForm,
    selectedTheme,
    pingVisible, 
    iconAttributes, 
}) {
    
    
    
    return (
        <Box id="development" className="relative" selectedTheme={selectedTheme}>
                <div className="w-full flex flex-col gap-4 items-start justify-start">
                <div className="flex flex-col gap-2 items-start justify-start">
                {/*<span className="text-xs font-medium bg-accent text-base-content px-2 py-0.5 rounded">$999+</span>*/}
                <h2 className={`text-3xl transition-all duration-150 mt-3`} style={{fontFamily: `Unbounded`}}>design app</h2>
                <p className="text-balance opacity-80">
                    Application UX/UI design.
                </p>
                </div>
                <div className="flex flex-col gap-4 items-start justify-start mt-4">
                <div className="flex flex-row gap-3 items-center transition-all duration-150">
                        <Icon
                            icon={'smartphone'}
                            {...iconAttributes}
                            /> Desktop & Mobile
                </div>
                
                <div className="flex flex-row gap-3 items-center transition-all duration-150">
                        <Icon
                            icon={selectedTheme.isDark ? 'moon' : 'sun'}
                            {...iconAttributes}
                            /> 
                            Dark/Light Themes
                </div>
                
                
                <div className="flex flex-row gap-3 items-center transition-all duration-150">
                        <Icon
                            icon={'code'}
                            {...iconAttributes}/>React JS UI-Kit
                </div>
                <div className="flex flex-row gap-3 items-center transition-all duration-150">
                        <Figma {...iconAttributes}/>Exported to Figma
                </div>
                </div>
                </div>  

                
                <div
            className="md:opacity-0 group-hover:opacity-100 flex flex-col w-full items-center pb-3 justify-end flex-grow transition-all duration-150"
            ><Button 
                color={'primary'}
                style={window?.innerWidth < 768 ? 'outlined' : 'ghost'}
                width={window?.innerWidth < 768 ? 'full' : 'auto'}
                text='pick me'
                onClick={handleScrollToForm}
            />
            </div>
                
                
        </Box>
    )
}

function Development({
    iconAttributes,
    selectedTheme,
    handleScrollToForm
}) {
    
    return (
        <Box id="development" className="relative" selectedTheme={selectedTheme}>
            <div
                className={`hidden md:flex
                    md:absolute w-full h-1/2 md:h-full right-0 -bottom-4
                    transition-all duration-500 `}
                
            />
            <div className="flex flex-col gap-2 items-start justify-start">
                {/*<span className="text-xs font-medium bg-primary text-base-0 px-2 py-0.5 rounded">
                    $2,999+
                </span>*/}
                <h2 className={`text-3xl transition-all duration-150 mt-3`} style={{fontFamily: `Unbounded`}}>build app</h2>
                <p className="text-balance opacity-80">
                    Design, build and deploy your app.
                </p>
            </div>
            <div className="flex flex-col gap-4 mt-4">
            <div className="flex flex-row gap-3 items-center">
                <Icon icon={'image'} {...iconAttributes} /> Includes UX/UI Design
            </div>
            <div className="flex flex-row gap-3 items-center hover:translate-x-1 transition-all">
                <Icon icon='code' {...iconAttributes}/> Frontend in React JS, TypeScript
            </div>
            <div className="flex flex-row gap-3 items-center hover:translate-x-1 transition-all">
                <Icon icon={'stack-overflow'} {...iconAttributes} />Python, Node JS Backend
            </div>
            <div className="flex flex-row gap-3 items-center hover:translate-x-1 transition-all">
                <Icon icon={'layers'} {...iconAttributes} />Backend & Server Setup
            </div>
            </div>
            <div
            className="md:opacity-0 group-hover:opacity-100 flex flex-col w-full items-center pb-3 justify-end flex-grow transition-all duration-150"
            ><Button 
                color={'primary'}
                style={window?.innerWidth < 768 ? 'outlined' : 'ghost'}
                width={window?.innerWidth < 768 ? 'full' : 'auto'}
                text='choose me'
                onClick={handleScrollToForm}
            />
            </div>
        </Box>
    );
}


function ProductStrategy({
    selectedTheme, iconAttributes, handleScrollToForm
}) {
    return (
        <Box className="relative" selectedTheme={selectedTheme}>
            <div className="flex flex-col gap-2 items-start justify-start">
            {/*<a className="text-xs font-medium bg-current-10 px-2 py-0.5 rounded"
            onClick={handleScrollToForm}
            >
                get a quote
            </a>*/}
            <h2 className={`text-3xl  transition-all mt-3 duration-150`}style={{fontFamily: `Unbounded`}}>
                    make a feature</h2>
            
            <p className="font-normal leading-relaxed opacity-80">
                Design or build a custom feature.
            </p>
            </div>
            <div className="flex flex-col gap-4 mt-3">
            <div className="flex flex-row gap-3 items-center">
                <Icon icon={'edit'} {...iconAttributes} />Infinite Canvas
            </div>
            <div className="flex flex-row gap-3 items-center">
                <Icon icon={'flare'} {...iconAttributes}  />AI products
            </div>
            <div className="flex flex-row gap-3 items-center">
                <Icon {...iconAttributes} icon='page' />Document Editors
            </div>
            <div className="flex flex-row gap-3 items-center">
                <Icon icon={'settings'} {...iconAttributes} />API Integrations
            </div>
            </div>
            <div
            className="md:opacity-0 group-hover:opacity-100 flex flex-col w-full items-center pb-3 justify-end flex-grow transition-all duration-150"
            ><Button 
                color={'primary'}
                style={window?.innerWidth < 768 ? 'outlined' : 'ghost'}
                width={window?.innerWidth < 768 ? 'full' : 'auto'}
                text='love me'
                onClick={handleScrollToForm}
            />
            </div>
            
        </Box>
    );
}