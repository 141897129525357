import { useContext } from 'react';
import { EditorContext } from '../EditorContext';
import FolderDisplay from './insideproject/FolderDisplay';
import ProjectDisplay from './insideproject/ProjectDisplay';
import { convertThemeVariablesToStyles } from "../canvas/helpers";
import Themes from './designsystem/themes/Themes';
import Install from './designsystem/install/Install';
import UIKitDisplay from './designsystem/uikit/UIKit';
import Brand from './designsystem/brandkit/Brand';
import OverviewHeader from './Header';
import RouterSetup from './config/RouterSetup';
import DatabaseSetup from './config/DatabaseSetup';
import FlowDisplay from './insideproject/FlowDisplay';
import Layouts from './designsystem/layouts/Layouts';

export default function ProjectView({refreshKey}) {
  const { view, ds, project, currentTheme, defaultTheme } = useContext(EditorContext);
  
  

  const themes = ds?.themes;
  const appliedTheme = currentTheme || defaultTheme; 
  const themeStyles = convertThemeVariablesToStyles(appliedTheme?.variables || []);
  const tab = view.inOverview || '/overview';
  console.log('tab', tab)

  const contentMap = {
    '/overview': <ProjectDisplay refreshKey={refreshKey}/>, 
    '/config/router': <RouterSetup project={project}/>,
    '/config/database': <DatabaseSetup project={project}/>,
    '/config/docs': <Install appliedTheme={appliedTheme}/>,
    '/ds/themes': <Themes themes={themes} appliedTheme={appliedTheme}/>,
    '/ds/layouts': <Layouts />,
    '/ds/brand': <Brand />,
    '/ds/uikit': <UIKitDisplay />,
    '/folder': <FolderDisplay refreshKey={refreshKey}/>,
    '/flow': <FlowDisplay refreshKey={refreshKey}/>
  }

  return (
    
    <div className='flex flex-col w-full max-w-screen items-center justify-stretch h-full overflow-y-scroll relative' style={{ zIndex: 100}} > 
        <div className="relative flex flex-col gap-2 pt-8 pb-2 px-4 pl-1 transition-all rounded-md
          text-base-content ring-[0.5px] ring-inset ring-base-200 bg-base-0 box-border h-full my-1"
          style={{ 
            overflow: 'hidden', 
            width: 'calc(100% - 16px)',
            maxWidth: 'calc(100% - 16px)',
            maxHeight: 'calc(100% - 16px)',
            ...(tab === 'designSystem' ? {...themeStyles} : {})
          }}>
            <OverviewHeader tab={tab} />

            {tab.startsWith('/ds') ? 
            <div className="flex px-3 py-3 overflow-hidden h-full w-full">
              {contentMap[tab]}
            </div> :
            contentMap[tab]}          
        </div>
    </div>
    

  );
  }

