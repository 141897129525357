import { useState, useContext, useEffect, useRef } from "react";
import * as UIKit from '../../../ui-kit/local/index'
import * as IconoirIcons from 'iconoir-react';
import { allIconNames} from "ui-kit/exports/react/components/iconMap";
import { v4 as uuidv4 } from 'uuid';
import { preparePrimitives } from "../sidebar/library/helpers";
import { prepareFrameJSXwithIDs } from "./helpers";
import Messages from "./Messages";
import { prepareAdjacentFrames } from "./helpers";
import { processResponse } from "./processResponse";
import UserContext from "../../../UserContext";
import { ButtonIcon } from "ui-kit/exports/react";

export default function SuperChat(props) {
  const { selector, handleAction, oldSet, view, streaming, setStreaming, actionsHistory, effectiveVariables, 
    notifications, setNotifications
  } = props
  const [conversationId, setConversationId] = useState(uuidv4());
  const { user } = useContext(UserContext);

  const sections = []
  const modules = []
  const layouts = []

  const enableChat = user.isPro || user.ispro || user.is_pro
  

  useEffect(() => {
    setConversationId(uuidv4());
  }, []);
    
    
    const { currentMonthUsage, usagehardlimit } = user;
    const currentUsage = currentMonthUsage?.total_cost
    
    const userOverLimit = !usagehardlimit || !currentUsage || currentUsage > usagehardlimit

  // Data for AI model
  // const selectedTemplates = templates?.flatMap(group => group.templates.map(item => ({ ...item, groupName: group.name })).filter(t => t.ai_template).map(t => ({apiName: t.apiName, jsx: t.jsx}))); 
  const allModules = modules?.flatMap(group => group.templates)
  const moduleTemplates = allModules?.filter(m => m.ai_template).map(m => ({apiName: m.apiName, jsx: m.jsx, ai_instructions: m.ai_instructions, allowedParents: m.allowedSections, type: 'module'})) || []
  

  const variables = effectiveVariables?.filter(v => v.type == 'color').map(v => ({api_name: v.api_name,value: v.value,type: v.type})) || [];
  const pageTemplates = oldSet
  const emailTemplates = pageTemplates?.filter(t => t.type == 'email').map(t => ({apiName: t.name, jsx: t.jsx})) || []
  let sectionTemplates = sections.flatMap(group => group.templates.map(item => ({ ...item, groupName: group.name }))); 
  sectionTemplates = [...sectionTemplates, ...emailTemplates]
  // console.log(sectionTemplates)
  
  const primitives = preparePrimitives(UIKit)
  // console.log(primitives)
  // messages we show in the chat
  const [displayMessages, setDisplayMessages] = useState([
    ...(!enableChat ? [] : [{role: 'assistant', content: 'Hi, what would you like your users to do?'}]),
  ])
  const displayMessagesRef = useRef(displayMessages);
  useEffect(() => {
    displayMessagesRef.current = displayMessages;
  }, [displayMessages]);
  
  // messages we 
  const [messages, setMessages] = useState([
    {role: 'system', content: 'system message to be inserted on the server side'},
    ...(!enableChat ? [] : [{role: 'assistant', content: 'Hi, what would you like your users to do?'}]),

  ]);
  const modelMessagesRef = useRef(messages);
  useEffect(() => {
    modelMessagesRef.current = messages;
  }, [messages]);
  
  const actionHistoryLengthRef = useRef(null);
  const frameObjects = selector.frame?.objects || [];
  // console.log('frame objects inside chat', frameObjects)
  const idPairsRef = useRef([]);
  const savedImagesRef = useRef([]);
  const generatedTemplatesRef = useRef([]);
  const imageURLsRef = useRef([]);
  const emptyFrame = selector.frame?.objects?.length == 0
  const pageFrames = selector.page?.frames?.filter(frame => !frame.isArchived).sort((a, b) => a.index - b.index) || [];
  
  const adjacentPages = prepareAdjacentFrames(pageFrames, selector.frame?.id) || []
  // console.log(sectionTemplates)
  // Data to persist during web socket open
  const frameRef = useRef(selector.frame);
  useEffect(() => {frameRef.current = selector.frame}, [frameObjects]);
  
  const [ws, setWs] = useState(null);

  const [userText, setUserText] = useState("");
  
  useEffect(() => {return () => {if (ws) {ws.close();}};}, [ws]);

  const handleWebSocketMessages = (event) => {
    // Persist data via refs throughout web socket since web socket only remembers initial state
    const currentFrame = frameRef.current;
    const currentFrameObjects = currentFrame?.objects;
    const currentDisplayMessages = displayMessagesRef.current
    const currentModelMessages = modelMessagesRef.current

    const data = JSON.parse(event.data);
    const { action, payload } = data;
    console.log(data);
    
    processResponse({
      action, 
      payload, 

      currentDisplayMessages, 
      setDisplayMessages, 
      displayMessagesRef, 

      currentModelMessages,
      setModelMessages: setMessages,
      modelMessagesRef,
      
      handleAction, 
      currentFrameObjects,
      currentFrame,
      generatedTemplatesRef, 
      idPairsRef, 
      savedImagesRef,
      sectionTemplates,

      streaming, 
      setStreaming, 
    
      notifications,
      setNotifications,
    });

  }
  
  const startWebSocketConnection = () => {
    let webSocketUrl;
    if (process.env.NODE_ENV === "development") {
      webSocketUrl = "ws://localhost:3300/ai/juno-chat";
    } else {
      const protocolPrefix =
        window.location.protocol === "https:" ? "wss://" : "ws://";
      webSocketUrl = `${protocolPrefix}${window.location.host}/ai/juno-chat`;
    }
    const webSocket = new WebSocket(webSocketUrl);

    setWs(webSocket);
    
    // webSocket.onopen = () => console.log("WebSocket Connected");
    webSocket.onmessage = handleWebSocketMessages;
    webSocket.onerror = (error) => console.error("WebSocket Error:", error);

    webSocket.onclose = (event) => {
      // console.log("WebSocket Disconnected", event.reason);
      setWs(null);
    };
    return webSocket;
};

const [connectionAttempts, setConnectionAttempts] = useState(0);
useEffect(() => {
  // No delay on the first attempt (when connectionAttempts is 0)
  const reconnectDelay = connectionAttempts === 0 ? 0 : 1000; // Delay in milliseconds for subsequent attempts

  if (!ws) {
    const timeoutId = setTimeout(() => {
      startWebSocketConnection();
      setConnectionAttempts(attempts => attempts + 1);
    }, reconnectDelay);

    return () => clearTimeout(timeoutId);
  }

  return () => {
    if (ws) {
      ws.close();
    }
  };
}, [ws, connectionAttempts]);

  const currentFlow = selector.page
  const flowInfo = `Feature Name: ${currentFlow?.name}; Feature Notes: ${currentFlow?.notes}`

  async function handleSubmit() {
    // console.log(userText)
    try {
      // console.log(selector)
      const jsx = prepareFrameJSXwithIDs(selector);
      
      const frameBefore = adjacentPages.find(frame => frame.description == 'frame before')
      
      const messagesForModel = [...messages, { role: 'user', content: 
        `Message from user: 
        ${userText}
        
        Context:
        User is looking at the following code: 
        ${jsx}

        ${frameBefore ? `If it's useful for completing the task, here's the frame that preceeds the current in the user flow:
        ${adjacentPages.find(frame => frame.description == 'frame before')?.jsx || 'none'}` : ''}
        You can use it for context only.
        `
      }];
      /*&
        
      */
  
      // we send modelMessages
      setMessages(messagesForModel);
      setDisplayMessages([...displayMessages, { role: 'user', content: userText }])
      setUserText('');
      
      const currentJSX = `
      FrameName: ${selector.frame?.name}
      FrameNotes: ${selector.frame?.notes}
      JSX: ${jsx}`

      const context = {
       
       selector: (({ folder, page, frame, object }) => ({ folder, page, frame, object }))(selector),
       jsx: currentJSX,
       layouts,
       prompt: userText,
       sections: sectionTemplates, 
       pages: pageTemplates,
       adjacentPages,
       templates: moduleTemplates,
       primitives, 
       iconSet: allIconNames, 
       variables, 
       skipTransform
      }
      const payload = {
        messages: messagesForModel,
        context, 
      };
      
      if (ws && ws.readyState === WebSocket.OPEN) {
        
        ws.send(JSON.stringify({type: 'message', payload, userId: user.id, conversationId}));
      } else {
        // console.log("WebSocket is not connected.");
      }
    } catch (error) {
      console.log(error);
    }
  }


  // SUBMIT ON ENTER
  function handleKeyDown(e) {
    e.stopPropagation()
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  }

  const handleTextAreaChange = (e) => {
    const newValue = e.target.value;
    
    if (newValue.length <= 3000) {
      setUserText(newValue);
      e.target.style.height = 'auto';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
  };

  function pauseStream() {
    setStreaming(false)
    setDisplayMessages([...displayMessages, {role: 'assistant', content: 'Paused'}])
    let newMessages = [...messages]
    const lastMessage = newMessages[newMessages.length - 1];
    if (lastMessage.role === 'assistant' && lastMessage.tool_calls && lastMessage.tool_calls.length > 0) {
      lastMessage.tool_calls.forEach(toolCall => {
        const toolResponseMessage = {
          tool_call_id: toolCall.id,
          role: "tool",
          name: toolCall.function.name,
          content: 'stream stopped before response was completed',
        };
    
        // Add the tool response message to the new messages
        newMessages.push(toolResponseMessage);
      });
    }
    setMessages(newMessages)

    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.close(); // Close the WebSocket connection
    }
    startWebSocketConnection(); // Reopen the WebSocket connection
  }

  const chatContainerRef = useRef(null);
  const showChat = true;
  function clearChat() {
    setDisplayMessages([{role: 'assistant', content: 'Hi, how can I help?'}])
    setMessages([
      {role: 'system', content: 'system message to be inserted on the server side'},
      {role: 'assistant', content: 'Hi, how can I help?'}]
    )
    setUserText('')
    setConversationId(uuidv4());
  }
  const [skipTransform, setSkipTransform] = useState(true)
  
  const processOptions = [
    { apiname: 'router', label: 'Auto'}, 
    { apiname: 'build_page', label: 'New Page'},
    { apiname: 'edit_page', label: 'Edit Page'},
    { apiname: 'edit_table', label: 'Edit Table'},
  ]
  const [currentProcess, setCurrentProcess] = useState(processOptions[0])
  
  const [isFocused, setIsFocused] = useState(false)

  return (
  <div className={`flex flex-col h-full w-full gap-3 text-sm justify-end font-medium`}
  style={{display: !showChat && 'none', maxHeight: '100vh', zIndex: 1000}}
  ref={chatContainerRef}
  >
      {<TopBar 
        activeChat={messages.length > 2}
        clearChat={clearChat} 
        currentProcess={currentProcess} 
        setCurrentProcess={setCurrentProcess} 
        processOptions={processOptions} />
        }

          <Messages 
        messages={displayMessages} 
        streaming={streaming} 
        clearChat={clearChat}
        />

        {/* INPUT AREA */}
        <div className="flex flex-col w-full gap-2 ">
        
        <div className={`w-full flex flex-row rounded-lg py-2 px-3 pr-2
        ${isFocused ? 'ring-[1.5px] ring-primary-focus bg-base-0 border border-primary' : 'bg-base-50  border border-transparent'}`}
        style={{minHeight: 120}}
        >
        
        {streaming ? 
        <div className="flex flex-row gap-3 w-full items-start justify-end">
        {streaming && 
        <ButtonIcon
          icon={'pause'}
          size={'small'}
          style={'ghost'}
          color={'primary'}
          onClick={pauseStream}
        />
        }
        </div>
         :

        <>
        <textarea
                value={ws ? userText : ''}
                type="text"
                className={`w-full h-full flex-grow text-sm bg-transparent leading-tight placeholder-base-400 text-base-content
                resize-none 
                `}
                placeholder={
                  enableChat ? streaming ? `disabled during AI response` : !ws ? `Disconnected` :`start typing...` :
                  'chat is on sabbatical. check back in a couple days'}
                autoFocus={true}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onKeyDown={handleKeyDown}
                onClick={e => e.stopPropagation()}
          onChange={handleTextAreaChange}
          disabled={enableChat ? (streaming || !ws) : true} //
          
            />
            <div className="w-6 flex-shrink-0 h-full flex flex-col justify-start">
            {<div className={`transition-all ease-in-out duration-300 bg-primary flex items-center rounded-full justify-center 
                           text-base-0 self-end w-6 h-6 
                          ${userText == '' ? 'bg-base-200' : 'hover:scale-105 cursor-pointer '}
                          ${!streaming ? 'opacity-100' : 'opacity-0'}`}
              style={{height: !streaming ? 24 : 0}}
              onClick={(e) => userText != '' && handleSubmit(e)}>
                <IconoirIcons.ArrowUp height={14} width={14} strokeWidth={2.5} />
          </div>}
          
          </div></>
            }
            
        </div>
      </div>
      </div>
    
  );
}


function TopBar({clearChat, activeChat, currentProcess, setCurrentProcess, processOptions}) {
    
    return (
      
      <div className={`relative rounded-lg py-1 flex flex-row items-center justify-between gap-2 text-xs flex-shrink-0 transition-all w-full text-base-600`}>
        
        {<SelectProcess 
          processOptions={processOptions} 
          currentProcess={currentProcess} 
          setCurrentProcess={setCurrentProcess} />}
        <div/>
        {activeChat && <div 
            className="flex-grow-0 flex-shrink-0 self-end items-center font-medium transition-all flex flex-row gap-2 
            bg-base-50 hover:bg-base-100 rounded-md px-2 py-1
            text-xs cursor-pointer"
            onClick={clearChat}
          ><IconoirIcons.Restart height={12} width={12} />clear</div>}
          
      </div>
    )
  
}


function SelectProcess({ currentProcess, setCurrentProcess, processOptions }) {
  
  const handleSelect = (e) => {
    
    setCurrentProcess(processOptions.find(option => option.apiname == e.target.value))
  }
  return (
    <div 
      className="relative flex flex-row gap-2 text-xs bg-base-50 hover:bg-base-100 rounded-md px-2 py-1 items-center cursor-pointer"
      onClick={() => document.getElementById('select-process').focus()}
    >
      <select 
        id="select-process"
        value={currentProcess?.apiname} 
        onChange={(e) => handleSelect(e)}
        
        className="bg-transparent flex-grow outline-none cursor-pointer appearance-none pr-3"
      >
        {processOptions?.map(option => (
          <option key={option?.apiname} value={option?.apiname}>
            {option?.label}
          </option>
        ))}
      </select>
      <IconoirIcons.ArrowSeparateVertical className="scale-75 absolute right-0 top-1/2 -translate-y-1/2 pointer-events-none"/>
    </div>
  );
}