import React, { useState, useRef, useContext, useEffect } from 'react';
import { EditorContext } from '../EditorContext';

import ZoomCanvas from './canvas/ZoomCanvas';
import ShareButton from './ShareButton';
import ModeSwitch from './canvas/ModeSwitch';
import Notifications from './Notifications';
import { Avatar, Button, ButtonIcon, Grid, InputText, ToggleSwitch } from 'ui-kit/exports/react';
import ThemeSwitcher from './ThemeSwitcher';
import UserContext from '../../../UserContext';
import { ArrowRight, Box, Cube, InputField, Link, NavArrowRight, PanoramaEnlarge, PlusSquare, Square, Text, ViewColumns3, ViewGrid } from 'iconoir-react';
import MicroNav from './micronav/MicroNav';


export default function Header() {
  const { view, selector, setView, handleAction } = useContext(EditorContext);
  const {user} = useContext(UserContext);
  const firstName = user?.firstname;
  const lastName = user?.lastname;

  const tooltipStyle = `absolute opacity-0 group-hover:opacity-100 invisible group-hover:visible whitespace-nowrap
  bg-gray-800 text-gray-200 font-normal rounded-sm px-2 py-0.5 text-2xs z-50 
  transition-all duration-75`
  
  const currentFrame = selector?.frame || {}

  function updateName(newName) {
    handleAction({
      type: 'UPDATE_FRAME',
      currentFrame: currentFrame,
      newFrame: { ...currentFrame, name: newName }
    })
  } 
  
  
  return (
    <div className={`h-full transition-all flex-shrink-0 flex flex-row items-center justify-between w-full text-base-content `}
    style={{
      width: `100%`, 
      zIndex: 100,   
    }}
    >
          <div className={`flex flex-row w-1/3 max-w-[320px] flex-shrink-0 gap-2 items-center pl-[2px] `} >
            {view.overview ? 
             <>
             </>
            :
            <>
              {/* View Swicth */ }
              <ButtonIcon size={'small'} 
              style='light'
              icon={'grid'} onClick={() => setView({...view, overview: !view.overview})}
              />
              {/* <ButtonIcon size={'small'} state={view.showFeatures ? 'active' : 'default'} icon={'layers'} onClick={() => setView({...view, showFeatures: !view.showFeatures})} />*/}
              
              {/* Open Pages <Button size={'small'} rightIcon={view.miniNav ? 'chevron-up' : 'chevron-down'} text={selector?.page?.name}
              onClick={()=> setView({...view, miniNav: !view.miniNav})} /> */}
              
              
              <ButtonIcon size={'small'} icon={'layers'} style={view.miniNav ? 'light' : 'ghost'}
              onClick={()=> setView({...view, miniNav: !view.miniNav})} />
              
              
              {/*<div className='flex flex-row items-center gap-2 max-w-48 h-12 items-center justify-start flex-grow overflow-hidden'>
                <MicroNav />
              </div>*/}
              

              {/* Frame Rename 
              <InputText key={selector.frame?.name} size={'small'} hasOutline={false} placeholder={''} bgColor={'none'}
              defaultValue={selector.frame?.name}
              onBlur={(e) => updateName(e.target.value)}
              />*/}
              <div className='flex flex-col ml-2'>
              <span className='text-xs opacity-70 font-normal flex gap-1 items-center' style={{fontSize: '11px'}}
              >
                {selector?.folder?.name} <NavArrowRight className='w-3 h-3'/>
                {selector?.page?.name}</span>
              <input 
                type='text'
                defaultValue={selector.frame?.name}
                onBlur={(e) => updateName(e.target.value)}
                placeholder=''
                className='bg-transparent text-base-content font-normal focus:ring-0 focus:border-0 focus:outline-none
                focus:bg-current-10 h-4 placeholder-base-content text-sm
                ring-0 ring-transparent border-0 border-transparent appearance-none'
              />
              </div>
            </>
            }
      </div>

      
    
    <div className='flex flex-row w-1/3 max-w-[320px] flex-shrink-0 items-center justify-end gap-3 pointer-events-auto'>
    <Notifications />
    {view.overview ?
     renderFolderViewControls({tooltipStyle, view, setView, firstName}) :
      renderCanvasControls({tooltipStyle, view, setView})
    }
    <ShareButton tooltipStyle={tooltipStyle}/>
    {/*
    <ButtonIcon color={'base-200'} style={'light'} size={'small'}
      icon={view.nightMode ? 'sun' : 'moon'}
      onClick={() => setView({...view, nightMode: !view.nightMode})}
    />*/}
    <Avatar
      initials={firstName}
      bgColor={'primary'}
      size={'32px'}
      type={'initials'}
      onClick={() => setView({...view, settings: 'user'})}   
      outlineColor={'accent'} 
    />
    </div>
    
    </div>
  );
}


function renderFolderViewControls({tooltipStyle, view, setView}) {
  
  return (
    <>
    </>
  )
}

function renderCanvasControls({tooltipStyle, view, setView}) {
  return (
    <>
    
    <ZoomCanvas tooltipStyle={tooltipStyle}/>
    {view.mode == 'editor' &&
    <ToggleSwitch 
      style='rectangle'
      size='large'
      checked={view.showOutlines}
      onChange={() => setView({...view, showOutlines: !view.showOutlines})}
    />}
    {/*<ThemeSwitcher tooltipStyle={tooltipStyle}/>*/}
    <ModeSwitch tooltipStyle={tooltipStyle}/>
    
    </>
  )
}
